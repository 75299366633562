.OLD-frame-container-PROTECT2 {
    background: var(--game-bg-color);
    padding: 0;
    margin: 0;
    width: 100%;
    overflow: hidden;
    border: none;
}

.frame-container-PROTECT2 {
    opacity: 1;
    position: absolute;
    background: var(--game-bg-color);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    min-height: 100%;
    min-width: 100%;
}

.frame-container-PROTECT2-spinner {
    opacity: 1;
    position: absolute;
    background: var(--game-bg-color);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    min-height: 100%;
    min-width: 100%;
}

.iframe-PROTECT2 {
    padding: 0;
    margin: 0;
    width: 510px;
    height: 900px;
    border: none;
}


