.dashboard-item {
    padding-bottom: 20px;
}

.pathway-welcome {
    background-color: var(--rich-black);
    color: white;
    border: solid 1px #FFFFFF30;
    border-radius: 10px;
    padding: 20px;
}

.my-details-welcome {
    background-color: var(--rich-black);
    color: white;
    border: solid 1px #FFFFFF30;
    border-radius: 10px;
    padding: 20px;
}