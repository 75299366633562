
.activity-success {
    color: green;
}

.activity-danger {
    color: red;
}

.activity-warning {
    color: goldenrod;
}

.activity-info {
    color: darkslateblue;
}

.activity-add {
    color: darkslateblue;
}
