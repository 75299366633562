.variant-empty {
    margin: auto;
    padding-top: 5px;
    padding-bottom: 30px;
    border: solid 1px darkgrey;
    border-radius: 5px;
    margin-right: 1px;
    margin-left: 1px;
}
.variant-pending {
    background-color: white;
    margin: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    border: solid 1px black;
    border-radius: 5px;
    margin-right: 1px;
    margin-left: 1px;
}
.variant-triggered {
    background-color: lightgoldenrodyellow;
    margin: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    border: solid 1px black;
    border-radius: 5px;
    margin-right: 1px;
    margin-left: 1px;
}
.variant-open {
    background-color: greenyellow;
    margin: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    border: solid 1px black;
    border-radius: 5px;
    margin-right: 1px;
    margin-left: 1px;
}
.variant-active {
    background-color: lightgreen;
    margin: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    border: solid 1px black;
    border-radius: 5px;
    margin-right: 1px;
    margin-left: 1px;
}
.variant-passed {
    background-color: green;
    color: white;
    margin: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    border: solid 1px black;
    border-radius: 5px;
    margin-right: 1px;
    margin-left: 1px;
}
.variant-failed {
    background-color: red;
    color: white;
    margin: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    border: solid 1px black;
    border-radius: 5px;
    margin-right: 1px;
    margin-left: 1px;
}
.variant-expired {
    background-color: darkred;
    color: white;
    margin: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    border: solid 1px black;
    border-radius: 5px;
    margin-right: 1px;
    margin-left: 1px;
}
