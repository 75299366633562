.sidebar-app-admindev {
    padding: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-right: 15px;
    margin-bottom: 10px;
    color: black;
    min-width: 65px;
    max-width: 65px;
    max-height: 690px;
    background-color: #EFEFFFDF;
    border: solid 1px #00000040;
    border-radius: 10px;
    -webkit-box-shadow: 0px 2px 10px rgba(0, 0, 50, 0.5);
    -moz-box-shadow: 0px 2px 10px rgba(0, 0, 50, 0.5);
    box-shadow: 0px 2px 10px rgba(0, 0, 50, 0.5);
}

    .sidebar-app-admindev > ul > li > a {
        color: black;
    }

.sidebar-app-admin {
    padding: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-right: 15px;
    margin-bottom: 10px;
    color: black;
    min-width: 65px;
    max-width: 65px;
    max-height: 650px;
    background-color: #EFEFFFDF;
    border: solid 1px #00000040;
    border-radius: 10px;
    -webkit-box-shadow: 0px 2px 10px rgba(0, 0, 50, 0.5);
    -moz-box-shadow: 0px 2px 10px rgba(0, 0, 50, 0.5);
    box-shadow: 0px 2px 10px rgba(0, 0, 50, 0.5);
}

    .sidebar-app-admin > ul > li > a > span {
        color: black;
    }

.sidebar-brand-text {
    font-size: 1.1rem;
    display: inline;
    text-decoration: none;
    color: black;
    padding-left: 15px;
    padding-top: 10px;
    margin-bottom: -10px;
}

.sidebar-divider {
    margin-right: 0px;
    min-width: 100%;
    max-width: 100%;
    color: #00000085;
}

.app-nav-icon {
    font-size: 1.5em;
}

.app-nav-img {
    width: 30px;
    height: 30px;
    margin-bottom: 10px;
}
