.full-login-page-carousel {
    min-height: calc(100vh - 140px);
    min-width: 100vw;
    max-height: calc(100vh - 140px);
    max-width: 100vw;
}

.full-login-page-carousel-item-splash {
    min-height: calc(100vh - 140px);
    min-width: 100vw;
    max-height: calc(100vh - 140px);
    max-width: 100vw;
    background: linear-gradient( 180deg, #31CFC0, #7884F5);
}

.full-login-page-carousel-item {
    min-height: calc(100vh - 140px);
    min-width: 100vw;
    max-height: calc(100vh - 140px);
    max-width: 100vw;
    background: linear-gradient( 180deg, #31CFC0, #7884F5);
}

.full-login-page-carousel-item-explainer1 {
    min-height: calc(100vh - 140px);
    min-width: 100vw;
    max-height: calc(100vh - 140px);
    max-width: 100vw;
    background: linear-gradient( 90deg, #7884F5, #A55DDE);
}

.full-login-page-carousel-item-explainer2 {
    min-height: calc(100vh - 140px);
    min-width: 100vw;
    max-height: calc(100vh - 140px);
    max-width: 100vw;
    background: linear-gradient( 90deg, #A55DDE, #EF57A9);
}

.full-login-page-carousel-item-explainer3 {
    min-height: calc(100vh - 140px);
    min-width: 100vw;
    max-height: calc(100vh - 140px);
    max-width: 100vw;
    background: linear-gradient( 90deg, #EF57A9, #EE645B);
}

.full-login-page-carousel-item-explainer4 {
    min-height: calc(100vh - 140px);
    min-width: 100vw;
    max-height: calc(100vh - 140px);
    max-width: 100vw;
    background: linear-gradient( 90deg, #EE645B, #E79951);
}

.full-login-page-carousel-item-explainer5 {
    min-height: calc(100vh - 140px);
    min-width: 100vw;
    max-height: calc(100vh - 140px);
    max-width: 100vw;
    background: linear-gradient( 90deg, #E79951, #EFC168);
}

.splash-logo-img {
    max-width: 80%;
    margin-top: 5vh !important;
    margin-bottom: 5vh !important;
    height: auto;
    width: auto;
    margin: auto;
}

@media (orientation: landscape) {
    .splash-img {
        margin: auto;
        max-width: calc(100vh - 200px);
        max-height: calc(100vh - 200px);
    }
}

@media (orientation: portrait) {
    .splash-img {
        max-width: calc(100vw);
        max-height: calc(100vw);
    }
}

.splash-img > img {
    width: 100%;
    height: 100%;
}

.explainer-img {
    margin: auto;
    max-width: calc(100vh - 140px);
    max-height: calc(100vh - 140px);
}

    .explainer-img > img {
        width: 100%;
        height: 100%;
    }

.carousel-caption {
    text-align: left;
    border-radius: 10px;
    background-color: antiquewhite;
    padding: 20px;
    color: black;
    margin-bottom: 30px;
    opacity: 0.8;
}
