.question-error-message {
    background-color: red;
    border: solid 1px white;
    color: white;
    padding-right: 5px;
    padding-left: 5px;
}

/* INPUTS ***********************************************************/
.hidden-date-input {
    color: white;
    max-width: 50px;
}

.inputgroup-clear-button {
    background-color: white;
    color: var(--rich-black);
    border: solid 1px lightgray;
    max-width: 50px;
}

.inputgroup-spacer {
    background-color: white;
    color: var(--rich-black);
    border: solid 1px lightgray;
    max-width: 50px;
    padding: 5px;
}

.inputgroup-HH {
    max-width: 65px;
    text-align: center;
}

.inputgroup-mm {
    max-width: 65px;
    text-align: center;
}

.inputgroup-DD {
    max-width: 55px;
    text-align: center;
}

.inputgroup-MM {
    max-width: 65px;
    text-align: center;
}

.inputgroup-YYYY {
    max-width: 85px;
    text-align: center;
}

.icon-upload {
    width: 160px;
}

    .icon-upload > img {
        min-width: 150px;
        min-height: 150px;
        width: 100%;
        padding-bottom: 3px;
        display: block;
        cursor: pointer;
        border: solid 1px darkgray;
    }

    .icon-upload > input {
        display: none;
    }

    .icon-upload > .icon-upload-delete {
        position: relative;
        transform: scale(0.7);
        left: 115px;
        top: -20px;
    }

input[type="radio"] {
    border-color: darkgray;
}

    input[type="radio"]:checked {
        border-color: darkgray;
    }

input[type="checkbox"] {
    border-color: darkgray;
}

    input[type="checkbox"]:checked {
        border-color: darkgray;
    }

input[type="range"]::-webkit-slider-runnable-track {
    border: solid 1px darkgray;
}

.rangeLabels {
    transform: translateY(-15px);
}

.rangeAmount {
    min-width: 50px;
}
