.footer-app {
    height: 65px;
    margin: 0;
    padding: 5px;
    background: var(--footer);
    overflow: hidden;
    border-top: solid 1px white;
    -webkit-box-shadow: 0px -2px 10px rgba(0, 0, 50, 0.5);
    -moz-box-shadow: 0px -2px 10px rgba(0, 0, 50, 0.5);
    box-shadow: 0px -2px 10px rgba(0, 0, 50, 0.5);
}

.footer-app-login {
    height: 140px;
    margin: 0;
    padding: 3px;
    background: var(--footer);
    overflow: hidden;
    border-top: solid 1px white;
    -webkit-box-shadow: 0px -2px 10px rgba(0, 0, 50, 0.5);
    -moz-box-shadow: 0px -2px 10px rgba(0, 0, 50, 0.5);
    box-shadow: 0px -2px 10px rgba(0, 0, 50, 0.5);
}

.footer-app-icons {
    margin: 0;
    padding: 0;
    margin-left: 230px;
    height: 75px;
    margin: 0;
    margin-left: 0px;
    overflow: hidden;
    font-size: 0.7em;
}

.app-footer-icon {
    width: 24px;
    height: 24px;
}

.app-copyright {
    color: #304040;
    font-size: 0.9em;
    line-height: 100%;
}

.app-footer-links {
    font-size: 0.9em;
    line-height: 100%;
}

    .app-footer-links > a {
        color: #607070;
    }

.login-button {
    width: 100%;
}

.login-menu-home {
    margin: auto;
}

.install-page {
    padding: 20px;
    padding-top: 70px;
}

.alert-badge {
    position: relative;
    left: -20px;
}