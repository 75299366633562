.mute-toggle {
    cursor: pointer;
}

    .mute-toggle > span {
        border: solid 1px white;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin: 3px;
        padding: 2px;
        color: white;
        font-size: 1.2em;
    }
