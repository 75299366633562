.frame-container-EMPTY {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: none;
}

.frame-container-EMPTY-spinner {
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    min-height: 100%;
    min-width: 100%;
}

.iframe-EMPTY {
    padding: 0;
    margin: 0;
    width: 1000px;
    height: 1000px;
    border: none;
}

.frame-container-COGNITRON {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: none;
}

.frame-container-COGNITRON-spinner {
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    min-height: 100%;
    min-width: 100%;
}

.iframe-COGNITRON {
    padding: 0;
    margin: 0;
    width: 465px;
    height: 465px;
    border: none;
}

.frame-container-AGATA {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: none;
}

.frame-container-AGATA-spinner {
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    min-height: 100%;
    min-width: 100%;
}

.iframe-AGATA {
    padding: 0;
    margin: 0;
    width: 750px;
    height: 550px;
    border: none;
}

.frame-container-PROTECT {
    background: var(--game-bg-color);
    padding: 0;
    margin: 0;
    width: 100%;
    overflow: hidden;
    border: none;
}

.frame-container-PROTECT-spinner {
    opacity: 1;
    position: absolute;
    background: var(--game-bg-color);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    min-height: 100%;
    min-width: 100%;
}

.iframe-PROTECT {
    padding: 0;
    margin: 0;
    width: 510px;
    height: 900px;
    border: none;
}


