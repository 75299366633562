.main-app {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 70px;
    padding-bottom: 90px;
    margin-left: 0px;
    max-width: 1500px;
    margin: auto;
}

.main-app-background {

}

.additional-page-app-background {
    background: var(--main-bg-color);
    min-height: 100vh;
}

.main-app-background-admin {
}

.app-page {

}

.homepage-alerts {
    position: absolute;
    top: 0px;
    padding: 8px;
    opacity: 0.9;
    z-index: 1000;
}

