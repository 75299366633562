:root {
    --main-bg-color: #525FA5; /* #727FF5; */
    --game-bg-color: #563086;
    --rich-black: #282339;
    --header: #000000E0;
    --footer: #000000E0;
    --bootstrapPrimary: #0275d8;
    --bootstrapSuccess: #5cb85c;
    --bootstrapInfo: #5bc0de;
    --bootstrapWarning: #f0ad4e;
    --bootstrapDanger: #d9534f;
    --bootstrapDark: #292b2c;
    --bootstrapLight: #f7f7f7;
}

.form-check-input {
    width: 1.1em;
    height: 1.1em;
}

html {
    font-size: 18px;
    -webkit-touch-callout: none; /* Safari */
    -webkit-user-select: none; /* Chrome */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.selectable {
    -webkit-user-select: text; /* Chrome */
    -moz-user-select: text; /* Firefox */
    -ms-user-select: text; /* Internet Explorer/Edge */
    user-select: text;
}

body {
    background-color: var(--main-bg-color);
    background: linear-gradient( 180deg, var(--main-bg-color) 400px, #A55DDE 800px, #EF57A9 1200px, #EE645B 1600px, #E79951 2000px, #EFC168 2400px);
    color: white;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    overscroll-behavior: none;
}

.tinyLabel {
    padding: 0;
    margin: 0;
    font-size: 0.6em;
    margin-bottom: -5px;
    color: darkgray;
}

.smallFont {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 0.55em;
}

@media screen and (orientation: landscape) {
    lock-portrait {
        /* Rotate the content container */
        transform: rotate(-90deg);
        transform-origin: left top;
        /* Set content width to viewport height */
        width: 100vh;
        /* Set content height to viewport width */
        height: 100vw;
        overflow-x: hidden;
        position: absolute;
        top: 100%;
        left: 0;
    }
}

/* TABLES ***********************************************************************/
.transparent-table td {
    background-color: transparent;
}

.transparent-table th {
    background-color: transparent;
}

.noEndBorder tr:last-child td {
    border-bottom: none;
}

/* COGNITIVE ITEM ************************************************************/

.cognitive-item-modal-body {
    margin: auto;
    width: 100%;
    height: 1000px;
    background-color: white;
}

.cognitive-item-summary {
    max-width: 1000px;
    margin: auto;
}

.cognitive-item-modal-test {
    background-color: white;
    color: var(--rich-black);
}

.cognitive-item-modal-game {
    background-color: white;
    color: var(--rich-black);
}

.cognitive-item-modal-footer-test {
    background: var(--footer);
    color: white;
    border-top: solid 1px white;
    height: 75px;
    padding-top: 5px;
}

.cognitive-item-modal-footer-game {
    background: var(--footer);
    color: white;
    border-top: solid 1px white;
    height: 75px;
    padding-top: 5px;
}

.cognitive-item-modal-body-test {
    background-color: white;
    color: var(--rich-black);
    margin-bottom: auto;
    width: 100%;
    height: 1000px;
}

.cognitive-item-modal-body-game {
    background-color: var(--game-bg-color);
    color: white;
    margin-bottom: auto;
    width: 100%;
    height: 1000px;
}

.cognitive-item-sub-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

/* MODALS ***********************************************************************/
.modal-content {
    height: 100%;
    background-color: var(--main-bg-color);
}

.modal-header-app {
    background: var(--header);
    color: white;
    border-bottom: solid 1px white;
}

.modal-footer-app {
    background: var(--footer);
    color: white;
    border-top: solid 1px white;
    height: 75px;
    padding-top: 5px;
}

.questionnaire-item-modal-body {
    background-color: #FFFEF7;
    color: var(--rich-black);
    margin-bottom: auto;
    width: 100%;
    height: 1000px;
}

.modal-confirm-header-app-light {
    background: var(--main-bg-color);
    color: white;
}

.modal-confirm-header-app-dark {
    background: #212529;
    color: white;
}

/* MODAL LAYERS ****************************************************************/

div[role="dialog"][aria-modal="true"]:nth-last-child(1) {
    z-index: 1125;
}

.modal-backdrop.show:nth-last-child(2) {
    z-index: 1100;
}

div[role="dialog"][aria-modal="true"]:nth-last-child(3) {
    z-index: 1075;
}

.modal-backdrop.show:nth-last-child(4) {
    z-index: 1050;
}

div[role="dialog"][aria-modal="true"]:nth-last-child(5) {
    z-index: 1040; /*1025*/
}

.modal-backdrop.show:nth-last-child(6) {
    z-index: 1030; /*1000*/
}

.pagination-dark li a {
    background-color: transparent;
    color: #FFFFFF !important;
    border-color: #404040;
}

    .pagination-dark li a:hover {
        background-color: #404040;
        color: #FFFFFF !important;
        border-color: #404040;
    }

    .pagination-dark li a:focus {
        background-color: transparent;
        color: #FFFFFF !important;
        border-color: #404040;
        mask-border: none;
    }

.pagination-light li a {
    background-color: transparent;
    color: #000000 !important;
    border-color: lightgray;
}

.close-app-x {
    font-size: 1.4em;
    line-height: 0px;
    padding: 0;
    margin: 0;
}

.pagination > li {
    width: 100%;
    text-align: center;
}

    .pagination > li a {
        color: var(--rich-black);
        font-weight: normal;
    }


.tabs-light ul > li > button {
    background-color: transparent !important;
    color: var(--rich-black) !important;
}

.tabs-dark ul > li > button {
    background-color: transparent !important;
    color: white !important;
}

/* SPINNERS ***********************************************************/
.busy-overlay {
    background: var(--main-bg-color);
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    min-height: 100%;
    min-width: 100%;
}

.busy-questionnaire-overlay {
    background: #FFFEF7;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    min-height: 100%;
    min-width: 100%;
}

.busy-overlay-local {
    background: lightgray;
    opacity: 0.2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    min-height: 100%;
    min-width: 100%;
}

.award-icon {
    display: block;
    width: 75%;
    margin: auto;
}

.award-icon-box {
    padding: 10px;
    margin: auto;
    text-align: center;
}

.award-presentation {
    max-width: 150px;
    max-height: 150px;
}

/* CARDS ***********************************************************/
.non-clickable-card {
    background-color: #FFFFFF70;
    color: var(--rich-black);
    box-shadow: 3px 3px 3px 0px #00005050;
}

.clickable-card {
    background-color: #FFFFFF70;
    color: var(--rich-black);
    box-shadow: 3px 3px 3px 0px #00005050;
    cursor: pointer;
}

    .clickable-card:link {
        background-color: #FFFFFFC0;
    }

    /* visited link */
    .clickable-card:visited {
        background-color: #FFFFFFC0;
    }

    /* mouse over link */
    .clickable-card:hover {
        background-color: #FFFFFFC0;
    }

    /* selected link */
    .clickable-card:active {
        background-color: #FFFFFFC0;
    }

.clickable-card-dark {
    background-color: var(--rich-black);
    color: white;
    box-shadow: 3px 3px 3px 0px #00005050;
    cursor: pointer;
}

    .clickable-card-dark:link {
        background-color: #00003CC0;
    }

    /* visited link */
    .clickable-card-dark:visited {
        background-color: #00003CC0;
    }

    /* mouse over link */
    .clickable-card-dark:hover {
        background-color: #00003CC0;
    }

    /* selected link */
    .clickable-card-dark:active {
        background-color: #00003CC0;
    }

tr.clickable-div-dark > td {
    margin-bottom: 1em;
}

.clickable-div-dark {
    background-color: transparent;
    border: solid 1px #C0C0D0;
    border-radius: 5px;
    padding: 3px;
    margin: 5px;
    cursor: pointer;
    box-shadow: 3px 3px 3px 0px #F0F0F050;
}

    .clickable-div-dark:link {
        background-color: aliceblue;
    }

    /* visited link */
    .clickable-div-dark:visited {
        background-color: aliceblue;
    }

    /* mouse over link */
    .clickable-div-dark:hover {
        background-color: aliceblue;
    }

    /* selected link */
    .clickable-div-dark:active {
        background-color: aliceblue;
    }

.clickable-div-light {
    background-color: transparent;
    border: solid 1px #4F4F6F;
    border-radius: 5px;
    padding: 3px;
    margin: 5px;
    cursor: pointer;
    box-shadow: 3px 3px 3px 0px #00001050;
    border-collapse: collapse;
}

    .clickable-div-light:link {
        background-color: #FFFFFF10;
    }

    /* visited link */
    .clickable-div-light:visited {
        background-color: #FFFFFF10;
    }

    /* mouse over link */
    .clickable-div-light:hover {
        background-color: #FFFFFF10;
    }

    /* selected link */
    .clickable-div-light:active {
        background-color: #FFFFFF10;
    }

.clickable-div-fullsize {
    width: 100%;
}

.clickable-div-halfsize {
    width: 47%;
}

.clickable-admin-status {
    cursor: pointer;
}

    .clickable-admin-status:link {
        color: white;
        filter: brightness(120%);
        border-color: black;
    }

    /* visited link */
    .clickable-admin-status:visited {
        filter: brightness(120%);
    }

    /* mouse over link */
    .clickable-admin-status:hover {
        filter: brightness(120%);
        border-color: black;
        box-shadow: 3px 3px 3px 0px #00001050;
        border-collapse: collapse;
    }

    /* selected link */
    .clickable-admin-status:active {
        filter: brightness(120%);
    }

/* ACCORDIAN ******************************************************/

.accordian-dashboard {
    background-color: transparent !important;
}

.accordian-item-dashboard-inactive {
    background-color: #C3A0FF70 !important;
    border: solid 1px #FFFFFF;
    border-radius: 10px !important;
    border-top-left-radius: 0px !important;
}

.accordian-item-dashboard {
    background-color: #FFFFFF80 !important;
    border: solid 1px #FFFFFF;
    border-radius: 10px !important;
    border-top-left-radius: 0px !important;
}

.accordian-header-dashboard {
}

.accordian-body-dashboard:is(.collapsed) {
    padding: 2px;
}

.accordian-body-dashboard:not(.collapsed) {
    /* border-top: solid 1px #FFFFFF30; */
    padding: 2px;
}

.accordion-button {
    background-color: transparent !important;
}

    .accordion-button:focus {
        box-shadow: none;
        color: black;
    }

    .accordion-button:is(.collapsed) {
        color: black;
    }

        .accordion-button:is(.collapsed)::after {
            display: none;
        }

    .accordion-button:not(.collapsed) {
        color: black;
        box-shadow: none;
    }

        .accordion-button:not(.collapsed)::after {
            display: none;
        }

/* BUTTONS **************************************************************/
.button-questionnaire {
    background-color: var(--bootstrapPrimary);
    color: white;
}
    .button-questionnaire:active {
        background-color: var(--bootstrapPrimary);
        color: white;
    }

    .button-questionnaire:hover {
        background-color: var(--bootstrapPrimary);
        color: white;
    }

    .button-questionnaire:disabled {
        background-color: var(--bootstrapDark);
        color: #FFFFFF50;
        border: none;
    }

.button-questionnaire-disabled {
    background-color: var(--bootstrapDark);
    color: darkgrey;
    border: none;
}

    .button-questionnaire-disabled:active {
        background-color: var(--bootstrapDark);
        color: darkgrey;
        border: none;
    }

    .button-questionnaire-disabled:hover {
        background-color: var(--bootstrapDark);
        color: darkgrey;
        border: none;
    }

    .button-questionnaire-disabled:disabled {
        background-color: var(--bootstrapDark);
        color: #FFFFFF50;
        border: none;
    }

.mandatory-question-flag {
    font-size: 0.8em;
    color: red;
}

.game-icon {
    width: 75px;
    height: 75px;
}

.game-progress-bar-dark {
    min-width: 12px;
    border: solid 1px #C0C0D0;
    border-radius: 7px;
    background: transparent;
    overflow: hidden;
}

    .game-progress-bar-dark > .game-progress {
        box-shadow: inset 1px 1px 3px 0px #101030;
    }

.game-progress-bar-light {
    min-width: 12px;
    border: solid 1px #4F4F6F;
    border-radius: 7px;
    background: transparent;
    overflow: hidden;
}

    .game-progress-bar-light > .game-progress {
        box-shadow: inset 2px 1px 3px 0px var(--rich-black);
    }

.busy-spinner {
    display: block;
    margin: auto;
}

.narrow-input {
    max-width: 150px;
}

.form-list {
    border: solid 1px lightgray;
    border-radius: 15px;
}

.was-validated .prevent-validation.form-control:valid,
.prevent-validation.form-control.is-valid {
    padding: 0.375rem 0.75rem;
    border-color: #ced4da;
    background-image: none;
}

.miniLabel {
    font-size: 0.7em;
    font-weight: bold;
}

.jumboLabel {
    font-size: 5em;
    font-weight: lighter;
    line-height: 100%;
}

h1 {
    font-size: 3em;
    font-weight: lighter;
    line-height: 100%;
}

.veryBigLabel {
    font-size: 3em;
    font-weight: lighter;
    line-height: 100%;
}

.tabs-app {
    background-color: transparent !important;
    border-bottom: none !important;
}

    .tabs-app > li > button {
        background-color: #00000060 !important;
        color: lightgray !important;
        font-weight: lighter;
        box-shadow: inset 1px 1px 4px 2px #10103090;
        border: solid 1px black !important;
        border-bottom: solid 1px white !important;
    }

        .tabs-app > li > button.active {
            background-color: transparent !important;
            color: white !important;
            font-weight: normal;
            box-shadow: none;
            border: solid 1px white !important;
            border-bottom: none !important;
        }

.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
    box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

.welcome-title {
    font-family: 'Trebuchet MS', sans-serif;
    color: #444444;
    font-size: 24pt;
}

.full-size {
    min-height: 100%;
    min-width: 100%;
}

.account-menu-dark button {
    background-color: transparent;
    color: white;
    border: none;
}

.account-menu-light button {
    background-color: transparent;
    color: var(--rich-black);
    border: none;
}

.account-menu-dark > div, .account-menu-light > div {
    background-color: white;
    color: var(--rich-black);
    margin-top: 10px;
    border: solid 1px darkgray;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 3px 3px 3px 0px #C0C0C0;
    width: 100%;
}

    .account-menu-dark > div > div, .account-menu-light > div > div {
        cursor: pointer;
        padding: 3px;
    }

        .account-menu-dark > div > div:hover, .account-menu-light > div > div:hover {
            background-color: whitesmoke;
        }

.wide-button {
    min-width: 300px;
    font-size: 1.2em;
}

.nav-link {
    display: inline;
    text-decoration: none;
    color: white;
}

/* es - Extra Small */
@media screen and (max-width: 360px) {
    .hide-if-smaller-than-es {
        display: none !important;
    }
}
/* es - Extra Small and bigger */
@media screen and (min-width: 360px) {
    .hide-if-greater-than-es {
        display: none !important;
    }
}
/* sm - Small */
@media screen and (max-width: 575px) {
    html {
        font-size: 16px;
    }
    .hide-if-smaller-than-sm {
        display: none !important;
    }
}
/* sm - Small and bigger */
@media screen and (min-width: 576px) {
    .hide-if-greater-than-sm {
        display: none !important;
    }
}
/* md - Smaller than Medium */
@media screen and (max-width: 767px) {
    .hide-if-smaller-than-md {
        display: none !important;
    }
}
/* lg - Medium and bigger */
@media screen and (min-width: 768px) {
    .hide-if-greater-than-md {
        display: none !important;
    }
}
/* lg - Smaller than Large */
@media screen and (max-width: 991px) {
    .hide-if-smaller-than-lg {
        display: none !important;
    }
}
/* lg - Large and bigger */
@media screen and (min-width: 992px) {
    .hide-if-greater-than-lg {
        display: none !important;
    }
}
/* xl - Smaller than Extra Large */
@media screen and (max-width: 1199px) {
    .hide-if-smaller-than-xl {
        display: none !important;
    }
}
/* xl - Extra Large and bigger */
@media screen and (min-width: 1200px) {
    .hide-if-greater-than-xl {
        display: none !important;
    }
}
/* xxl - Smaller than Extra/Extra Large */
@media screen and (max-width: 1399px) {
    .hide-if-smaller-than-xxl {
        display: none !important;
    }
}
/* xxl - Extra/Extra Large and bigger */
@media screen and (min-width: 1400px) {
    .hide-if-greater-than-xxl {
        display: none !important;
    }
}
